import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import '../styles/Sidebar.css';

const Sidebar = ({ isOpen, setIsOpen }) => {
  const { translate, language, setLanguage } = useLanguage();

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape' && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscKey);
    return () => document.removeEventListener('keydown', handleEscKey);
  }, [isOpen, setIsOpen]);

  const menuItems = [
    { text: translate('menu.home'), path: '/' },
    { text: translate('menu.hotel'), path: '/hotel' },
    { text: translate('menu.rooms'), path: '/rooms' },
    { text: translate('menu.lanzarote'), path: '/lanzarote' },
    { text: translate('menu.links'), path: '/links' },
    { text: translate('menu.sustainability'), path: '/sustainability' },
  ];

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'es' : 'en');
  };

  return (
    <>
      <button 
          className={`menu-button ${isOpen ? 'sidebar-open' : ''}`}
          onClick={() => setIsOpen(!isOpen)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              setIsOpen(!isOpen);
            }
          }}
          aria-label={isOpen ? "Close menu" : "Open menu"}
          aria-expanded={isOpen}
          aria-controls="sidebar-nav"
        >
        <div className='menu-button__icon'></div>
      </button>

      <nav id="sidebar-nav" className={`sidebar ${isOpen ? 'open' : ''}`}>
        <ul className="nav-list">
          {menuItems.map((item) => (
            <li key={item.text} className="nav-item">
              <Link 
                to={item.path} 
                className="nav-link"
                onClick={() => {
                  setIsOpen(false);
                  window.scrollTo({ top: 0 });
                }}
                tabIndex={isOpen ? 0 : -1}
              >
                {item.text}
              </Link>
            </li>
          ))}
          <li>
            <button 
              className="language-button"
              onClick={toggleLanguage}
              tabIndex={isOpen ? 0 : -1}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>
              {language === 'en' ? 'Español' : 'English'}
            </button>
          </li>
        </ul>
      </nav>

      <div 
        className={`overlay ${isOpen ? 'visible' : ''}`} 
        onClick={() => setIsOpen(false)}
        style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
      />
    </>
  );
};

export default Sidebar;