import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLanguage } from '../context/LanguageContext';

const Links = () => {
  const { translate } = useLanguage();
  const [isImageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      <Helmet>
        <title>{translate('links.meta.title')}</title>
        <meta name="description" content={translate('links.meta.description')} />
      </Helmet>
      <div className="links-container">
        <div className="hero">
          <figure>
            <img
              src={`${process.env.PUBLIC_URL}/img/lanzarote-hotel-rural-mar.webp`}
              onLoad={() => setImageLoaded(true)}
              alt="paisaje de lanzarote" />
          </figure>
          {isImageLoaded &&
            <div className="hero__text">
              <h1>{translate('links.linksInt')}</h1>
              <div className="hero__links">
                <a href="https://turismolanzarote.com" target="_blank" rel="noreferrer noopener">Turismo Lanzarote</a>
                <a href="http://www.geoparquelanzarote.org" target="_blank" rel="noreferrer noopener">Geoparque</a>
                <a href="http://www.cactlanzarote.com" target="_blank" rel="noreferrer noopener">Centros de Arte, Cultura y Turismo</a>
                <a href="http://fcmanrique.org" target="_blank" rel="noreferrer noopener">Fundación César Manrique</a>
                <a href="http://culturalanzarote.com" target="_blank" rel="noreferrer noopener">Agenda cultural Lanzarote</a>
                <a href="https://acasajosesaramago.com" target="_blank" rel="noreferrer noopener">{translate('links.saramago')}</a>
                <a href="http://www.ayuntamientodetias.es" target="_blank" rel="noreferrer noopener">{translate('links.ayto')}</a>
              </div>
            </div>
          }
        </div>
        <div className="media-container padded-text" id="media-container">
          <h2>{translate('general.prensa')}</h2>
          <div className="media-container__links">
            <div className='media-container__videos'>
              <a className="media-container__video" href="https://facebook.com/watch/?v=237333525594632&vanity=loslirioshotellanzarote" target="_blank" rel="noreferrer noopener">
                <img src={`${process.env.PUBLIC_URL}/img/RTC.webp`} alt="" />
                <span>Radio Televisión Canaria - Noticias</span>
              </a>
              <a className="media-container__video" href="https://x.com/RTVCCanarias/status/1707053752346136821" target="_blank" rel="noreferrer noopener">
                <img src={`${process.env.PUBLIC_URL}/img/RTC-2.webp`} alt="" />
                <span>Radio Televisión Canaria - Noticias</span>
              </a>
              <a className="media-container__video" href="https://www.youtube.com/watch?v=VNox9SPdoU0" target="_blank" rel="noreferrer noopener">
                <img src={`${process.env.PUBLIC_URL}/img/una-hora-menos.webp`} alt="" />
                <span>Radio Televisión Canaria - Una hora menos</span>
              </a>
            </div>
            <a href="https://elperiodicodelanzarote.com/economia/42546-el-hotel-rural-los-lirios-triunfa-en-los-premios-europeos-grinn-awards-2022-de-turismo-sostenible" target="_blank" rel="noreferrer noopener">
              <span>El periódico de Lanzarote</span>
            </a>
            <a href="https://www.hosteltur.com/comunidad/nota/030661_los-hoteles-independientes-espanoles-triunfan-en-los-grinn-awards.html" target="_blank" rel="noreferrer noopener">
              <span>Hosteltur</span>
            </a>
            <a href="https://www.lavozdelanzarote.com/ekonomus/turismo/hotel-tias-elegido-mejor-alojamiento-sostenible-espana_215333_102.html" target="_blank" rel="noreferrer noopener">
              <span>La voz de Lanzarote</span>
            </a>
            <a href="https://www.masscultura.com/mass/el-hotel-rural-los-lirios-triunfa-en-los-premios-europeos-de-turismo-sostenible/" target="_blank" rel="noreferrer noopener">
              <span>MassCultura</span>
            </a>
            <a href="https://upgradedpoints.com/travel/hotels/best-boutique-hotels-in-lanzarote/" target="_blank" rel="noreferrer noopener">
              <span>Upgraded points</span>
            </a>
            <a href="https://tribunadecanarias.es/antonio-castillo-el-turista-valora-la-historia-de-la-edificacion-el-trato-cercano-que-le-brindamos-y-la-calidad-del-producto-local-que-ofrecemos-en-los-desayunos/" target="_blank" rel="noreferrer noopener">
              <span>Tribuna de Canarias</span>
            </a>
            <a href="https://www.puertodelcarmen.com/boletin-informativo-semanal-19-04-2024/" target="_blank" rel="noreferrer noopener">
              <span>Boletín Puerto del Carmen</span>
            </a>
            <a href="https://viajar.elperiodico.com/hoteles/apurando-estacion-fria-alojamientos-perfectos-97875463" target="_blank" rel="noreferrer noopener">
              <span>Mención en revista Viajar</span>
            </a>
          </div>
        </div>
        <div className='credits'>
          <h2>{translate('links.creditos')}</h2>
          <span>
            {translate('links.arquitectura')}
            <a href="http://www.joseluismunozarquitectura.com" target="_blank" rel='noreferrer noopener'>Jose Luis Muñoz</a>
            <span> & </span>
            <a href="https://facebook.com/arquitectoslanzarote" target="_blank" rel='noreferrer noopener'>Alejandro Muñoz</a>
          </span>
          <span>
            {translate('links.fotografia')}
            Alberto Martín
          </span>
          <span>
            {translate('links.desarrollo')}
            <a href="https://www.fromdesigntocode.tech" target="_blank" rel='noreferrer noopener'>Juan González</a>
            </span>
        </div>
      </div>
    </>
  );
};

export default Links;