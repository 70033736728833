import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLanguage } from '../context/LanguageContext';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../styles/Swiper.css';

const RoomSwiper = lazy(() => import('../components/RoomSwiper'));

const RoomCard = ({ room, index, isActive, onRoomClick, onClose }) => (
  <div className='room-card'>
    <div
      className="room-card__content"
      onClick={() => onRoomClick(index)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          onRoomClick(index);
        }
      }}
      role="button"
      tabIndex={0}
      aria-expanded={isActive}
      aria-label={`${room.title} - ${room.description}`}
    >
      <figure>
        <img src={room.mainImage} alt={room.mainAlt} />
      </figure>
      <div className="room-card__text">
        <h2>{room.title}</h2>
        <p>{room.description} {room.surface}</p>
      </div>
    </div>

    {isActive && (
      <Suspense fallback={<div className="swiper-loading"><div className="loader"></div></div>}>
        <RoomSwiper room={room} onClose={onClose} />
      </Suspense>
    )}
  </div>
);

const Rooms = () => {
  const { translate } = useLanguage();
  const [activeSwiperIndex, setActiveSwiperIndex] = useState(null);

  const roomsData = [
    {
      mainImage: `${process.env.PUBLIC_URL}/img/salon-suite-hotel-rural-los-lirios-lanzarote.webp`,
      mainAlt: 'salón de la suite',
      title: translate('rooms.suite'),
      description: translate('rooms.suiteTxt'),
      surface: translate('rooms.suiteSuperf'),
      swiperLabel: 'Imágenes de la suite',
      images: [
        { src: `${process.env.PUBLIC_URL}/img/salon-suite-hotel-rural-los-lirios-lanzarote.webp`, alt: 'salón de la suite' },
        { src: `${process.env.PUBLIC_URL}/img/suite-hotel-rural-los-lirios-lanzarote.webp`, alt: 'suite' },
        { src: `${process.env.PUBLIC_URL}/img/aseo-suite-hotel-rural-los-lirios-lanzarote.webp`, alt: 'baño de la suite' },
        { src: `${process.env.PUBLIC_URL}/img/suite-sala-estar.webp`, alt: 'sala de estar de la suite' }
      ]
    },
    {
      mainImage: `${process.env.PUBLIC_URL}/img/cama-doble-hotel-rural-los-lirios-lanzarote.webp`,
      mainAlt: 'habitación doble',
      title: translate('rooms.doble'),
      description: translate('rooms.dobleTxt'),
      surface: translate('rooms.dobleSuperf'),
      swiperLabel: 'Imágenes de la habitación doble',
      images: [
        { src: `${process.env.PUBLIC_URL}/img/cama-doble-hotel-rural-los-lirios-lanzarote.webp`, alt: 'habitación doble' },
        { src: `${process.env.PUBLIC_URL}/img/habitacion-doble-hotel-rural-los-lirios-lanzarote.webp`, alt: 'habitación doble' },
        { src: `${process.env.PUBLIC_URL}/img/aseo-doble-hotel-rural-los-lirios-lanzarote.webp`, alt: 'aseo de la habitación doble' },
        { src: `${process.env.PUBLIC_URL}/img/habitacion-doble-los-lirios-cama.webp`, alt: 'cama de la habitación doble' }
      ]
    },
    {
      mainImage: `${process.env.PUBLIC_URL}/img/cama-superior-hotel-rural-los-lirios-lanzarote.webp`,
      mainAlt: 'habitación doble superior',
      title: translate('rooms.dobleSup'),
      description: translate('rooms.dobleSupTxt'),
      surface: translate('rooms.dobleSupSuperf'),
      swiperLabel: 'Imágenes de la habitación doble superior',
      images: [
        { src: `${process.env.PUBLIC_URL}/img/cama-superior-hotel-rural-los-lirios-lanzarote.webp`, alt: 'cama de la habitación doble superior' },
        { src: `${process.env.PUBLIC_URL}/img/habitacion-superior-hotel-rural-los-lirios-lanzarote.webp`, alt: 'habitación doble superior' },
        { src: `${process.env.PUBLIC_URL}/img/doble-superior-hotel-rural-los-lirios-lanzarote.webp`, alt: 'habitación doble superior' },
        { src: `${process.env.PUBLIC_URL}/img/aseo-doble-sup-hotel-rural-los-lirios-lanzarote.webp`, alt: 'aseo de la habitación doble superior' },
        { src: `${process.env.PUBLIC_URL}/img/habitacion-superior-los-lirios-twin.webp`, alt: 'camas de la habitación doble superior' },
        { src: `${process.env.PUBLIC_URL}/img/habitacion-superior-los-lirios-ventana.webp`, alt: 'ventana de la habitación' }
      ]
    },
    {
      mainImage: `${process.env.PUBLIC_URL}/img/cama-terraza-hotel-rural-los-lirios-lanzarote.webp`,
      mainAlt: 'habitación doble con terraza del hotel',
      title: translate('rooms.dobleTerraza'),
      description: translate('rooms.dobleTerrazaTxt'),
      surface: translate('rooms.dobleTerrazaSuperf'),
      swiperLabel: 'Imágenes de la habitación con terraza',
      images: [
        { src: `${process.env.PUBLIC_URL}/img/cama-terraza-hotel-rural-los-lirios-lanzarote.webp`, alt: 'habitación doble con terraza' },
        { src: `${process.env.PUBLIC_URL}/img/terraza-hotel-rural-los-lirios-lanzarote.webp`, alt: 'habitación doble con terraza' },
        { src: `${process.env.PUBLIC_URL}/img/aseo-terraza-hotel-rural-los-lirios-lanzarote.webp`, alt: 'aseo doble con terraza' },
        { src: `${process.env.PUBLIC_URL}/img/cama-terraza-hotel-rural-los-lirios-lanzarote.webp`, alt: 'cama de la habitación' },
        { src: `${process.env.PUBLIC_URL}/img/habitacion-terraza.webp`, alt: 'terraza de la habitación' }
      ]
    }
  ];

  useEffect(() => {
    let newSwiper = null;
    
    if (activeSwiperIndex !== null) {
      newSwiper = new Swiper('.swiper', {
        modules: [Navigation, Pagination],
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      });
    }

    // Cleanup function
    return () => {
      if (newSwiper) {
        newSwiper.destroy();
      }
    };
  }, [activeSwiperIndex]);

  return (
    <>
      <Helmet>
        <title>{translate('rooms.meta.title')}</title>
        <meta name="description" content={translate('rooms.meta.description')} />
      </Helmet>
      <div className="rooms-container">
        <div className="hero">
          <figure>
            <img src={`${process.env.PUBLIC_URL}/img/suite-hotel-rural-los-lirios-lanzarote.webp`} alt="suite del hotel rural los lirios" />
          </figure>
          <div className="hero__text">
            <h1>{translate('rooms.title')}</h1>
            <p>{translate('rooms.heroSubtitle')}</p>
          </div>
        </div>
        <div className="room-cards-container">
          {roomsData.map((room, index) => (
            <RoomCard
              key={index}
              room={room}
              index={index}
              isActive={activeSwiperIndex === index}
              onRoomClick={setActiveSwiperIndex}
              onClose={() => setActiveSwiperIndex(null)}
            />
          ))}
        </div>
        <div className="padded-text">
          {translate('rooms.habitaciones1')}
        </div>
      </div>
    </>
  );
};

export default Rooms;