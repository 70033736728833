import React, { useState, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import ContactHeader from './components/ContactHeader';
import Footer from './components/Footer';
import { LanguageProvider } from './context/LanguageContext';
import "./styles/vars.css";
import './styles/layout.css';
import './styles/booking.css';

// Import pages
import Home from './pages/Home';
import Hotel from './pages/Hotel';
import Rooms from './pages/Rooms';
import Lanzarote from './pages/Lanzarote';
import Links from './pages/Links';
import Sustainability from './pages/Sustainability';
import Legal from './pages/Legal';

function AppContent() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let lastScrollY = 0;
    const animatedElements = document.querySelectorAll('.animate-on-scroll');
    const observedElements = new Set();

    const isInViewport = (element) => {
      const rect = element.getBoundingClientRect();
      const elementHeight = rect.height;
      const visibleHeight = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
      const visiblePercentage = (visibleHeight / elementHeight) * 100;
      
      return visiblePercentage >= 70;
    };

    // Reset animation states on route change
    animatedElements.forEach(element => {
      element.classList.remove('animate');
    });
    observedElements.clear();

    // Initial check for elements already in viewport on page load
    animatedElements.forEach(element => {
      element.classList.remove('animate');
    });
    observedElements.clear();

    const handleScroll = () => {
      const nav = document.querySelector('.kw_container');
      if (nav) {
        const currentScrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollPosition = window.scrollY + windowHeight;

        if (currentScrollY > 100) {
          nav.classList.add('scrolled');
        } else {
          nav.classList.remove('scrolled');
        }

        if (documentHeight - scrollPosition < 100) {
          nav.classList.add('near-bottom');
        } else {
          nav.classList.remove('near-bottom');
        }
        lastScrollY = currentScrollY;

        // Animation logic - only check elements that haven't been animated yet
        animatedElements.forEach(element => {
          if (!observedElements.has(element) && isInViewport(element)) {
            element.classList.add('animate');
            observedElements.add(element);
          }
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Trigger initial check
    setTimeout(handleScroll, 100);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]);

  return (
    <>
      <Sidebar isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
      <ContactHeader />
      <main className={`main-content ${isMenuOpen ? 'shifted' : ''}`}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hotel" element={<Hotel />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/lanzarote" element={<Lanzarote />} />
          <Route path="/links" element={<Links />} />
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer />
      </main>
    </>
  );
}

function App() {
  return (
    <HelmetProvider>
      <LanguageProvider>
        <Router>
          <AppContent />
        </Router>
      </LanguageProvider>
    </HelmetProvider>
  );
}

export default App;