import React, { createContext, useState, useContext } from 'react';

const translations = {
  en: {
    general: {
      logotext: "RURAL HOTEL",
      reserva: "Book through our website and get a 10% discount!",
      pagos: "We don't charge any advance fees; you'll pay at the hotel at the end of your stay.",
      avisos: "The hotel is not suitable for children under 13 years old. We regret to inform you we do not have extra beds. Please get in touch previously if you have pets.",
      verHabitaciones: "See our rooms",
      prensa: "Our hotel in media",
    },
    menu: {
      home: "Home",
      hotel: "Hotel",
      rooms: "Rooms",
      lanzarote: "Lanzarote",
      links: "Links",
      sustainability: "Sustainability",
      legal: "Legal notice"
    },
    home: {
      meta: {
        title: 'Los Lirios Rural Hotel in Lanzarote',
        description: 'New rural hotel in Lanzarote, we are starting this new adventure with enthusiasm and excitement, share it with us!'
      },
      title: "Welcome to Los Lirios",
      welcome: "Discover our charming hotel in the heart of Lanzarote",
      heroTitle: "Privacy, that luxury...",
      heroSubtitle: "We are waiting for you in our cozy hotel, away from crowds in a unique environment.",
      verCasa: "See the hotel",
      lanzaroteHighlighted: "Lanzarote is a unique environment for many reasons. It was declared a Biosphere Reserve by UNESCO in 1993. ",
      lanzaroteTxt1: "Its enormous environmental wealth, its warm temperatures all year round, along with controlled urban planning, makes this island one of the best valued tourist destinations in Europe.",
      lanzaroteTxt2: "Lanzarote is much more than sun and beaches; this island offers a wide range of experiences for visitors to enjoy. With a multitude of sports activities, cultural and ethnographic visits, art galleries, archaeological sites, impressive volcanic landscapes, routes through wineries, gastronomy... and much more.",
      compartido: "A shared dream",
      casaHighlighted: "Back in the 1980s, two German friends, full of hope and wanting to break away from the mundane routine, decided to embark on an adventure.",
      casaTxt1: "They bought this farm in a dilapidated state and carried out intensive renovation work on it for several years in order to bring it back to life. They created the gardens and transformed old rooms into the patios that we now enjoy.",
      casaTxt2: "When the friends first bought the farm, they had dreamed of setting up a small hotel, but life got on the way. In 2014 we arrived at the house and we continue to shape this place into what it has become today. Mr. Adam and his friend Karl have been fundamental pieces in making this shared dream come true and we will always be grateful to them.",
      isla: "Discover our island",
      seemorelink: "See more"
    },
    hotel: {
      meta: {
        title: 'Our Hotel in Lanzarote',
        description: 'We are very proud of our house, which connects us with the traditional way of life in Lanzarote.'
      },
      title: "Our hotel",
      heroSubtitle: "Make yourself at home",
      lacasaAntonio: "I have always been fascinated by special places, places that differ from others by offering something that makes them unique. When I first arrived here in Lanzarote, I knew that the island was special and when I entered the door of this house for the first time, I knew this was where I belonged. It brings me great satisfaction to be able to share with you, dear guests, this beautiful place. I hope you enjoy it.",
      lacasaFirma: "Antonio Castillo, Los Lirios Hotel Rural Manager.",
      lacasaServ0: "We include:",
      lacasaServ1: "Welcome drink and aperitif",
      lacasaServ2: "Fantastic breakfast",
      lacasaServ3: "Private parking",
      lacasaServ4: "WiFi Connection",
      lacasaServ5: "Garden areas",
      lacasaServ6: "Outdoor swimming pool",
      lacasaServ7: "Solarium terrace",
      lacasaServ8: "laundry service",
      lacasaServ10: "Daily room cleaning",
      lacasaServ11: "Tourist information",
      lacasaOcio0: "Leisure activities:",
      lacasaOcio1: "Tourist excursion",
      lacasaOcio2: "Wine tours",
      lacasaOcio3: "Fishing",
      lacasaOcio4: "Hiking",
      lacasaOcio5: "Cycling",
      lacasaOcio6: "Windsurf",
      lacasaOcio7: "Surfing",
      lacasaOcio8: "Kitesurfing",
      lacasaOcio9: "Scuba diving",
      ofrecemos: "We also offer ",
      lacasaHighlighted: "The house, a heritage asset protected by the Government of the Canary Islands, dates from approximately the first half of the 18th century.",
      lacasa1: "It is a “terrera house”, a popular building that once served as a traditional agricultural house for farmlands and livestock. The materials used are very basic, local stone, wood and lime, but very effective in isolating from humidity and heat, as well as being totally sustainable. These types of buildings are perfectly integrated into the landscape and the natural surroundings of Lanzarote; and form a huge part of the island´s unique visual identity.",
      lacasa2: "The main structure, walls, central patio, wooden floors, some stone pavements, the era, and many unique elements, such as the still in the main patio, the volcanic stone basin, niches, cupboards and windows are preserved intact from the original construction. All of this enhances the historical value of the building and connects us with the traditional way of life in Lanzarote.",
      lacasa3: "The various interventions carried out over the years for the purposes of its restoration, conservation and finally adaptation to a hotel, have been undertaken with the utmost respect of the building, keeping its original integrity.",
      lacasacaption: "State of the house in the 1970s.",
      tias1: "Tías, our village",
      tias2: "The location of the house is very advantageous, set in a rural environment and at the same time it offers easy access to all the services you may need, like: supermarkets, pharmacies, shops and restaurants of all kinds, police or heath center.",
      tias3: "The municipality of Tías is very diverse, with beaches and rural environments, it encompasses bustling tourist centers and small agricultural towns. The stunning area of ​​La Geria and the beaches of Puerto del Carmen are among the best on the island and should definitely be highlighted on the list of places to visit. In the urban zone of Tías there are several examples of typical churches and the fascinating house-museum of the writer and Nobel Prize winner José Saramago, who lived in Tías for his last 17 years and wrote some of his best works here."
    },
    rooms: {
      meta: {
        title: 'Choose your room',
        description: 'Comfortable rooms with charm in a natural setting'
      },
      title: "Rooms",
      heroSubtitle: "All our rooms are comfortable and cozy.",
      habitaciones1: "Air-conditioned rooms with wooden floors. They all have a private bathroom and are equipped with Wi-Fi, a smart TV with satellite connection, hairdryer, minibar and a kettle with infusions. Complimentary daily housekeeping service is included. Fantastic breakfast included. A welcome drink and snack are provided (for arrivals until 3:00 p.m.).",
      doble: "Double",
      dobleTxt: "Queen sized bed.",
      dobleSuperf: "Size of the room: 17m2.",
      dobleTerraza: "Double with private terrace",
      dobleTerrazaTxt: "Queen sized bed and terrace.",
      dobleTerrazaSuperf: "Size of the room: 20 m2 + terrace 24 m2.",
      dobleSup: "Superior double bed",
      dobleSupTxt: "With king sized bed or with two single beds.",
      dobleSupSuperf: "Size of the room: 20m2.",
      suite: "Suite",
      suiteTxt: "King sized bed.",
      suiteSuperf: "Size of the room: 37m2. Separated living room and bedroom."
    },
    lanzarote: {
      meta: {
        title: 'Lanzarote, an island of another dimension',
        description: 'Sports activities, cultural and ethnographic visits, art centres, archaeological sites, volcanic landscapes, winery tours, gastronomy...'
      },
      title: "Welcome to Lanzarote",
      heroSubtitle: "An island of another dimension.",
      lanzarote1: "Arriving in Lanzarote is like entering another dimension. Its natural environment is fascinating, the violent volcanic nature combined with semi-desert bioclimatic conditions have created a unique natural landscape of extraordinary originality.",
      lanzarote2: "The institutions have managed to keep this very special environment safe from tourist growth and the construction boom. Approximately 40% of the island\"s surface is protected by some law.",
      lanzarote3: "Lanzarote\"s volcanic activity began eleven million years ago and today the island\"s landscape is made up of more than 300 volcanic cones. The eruptions of 1730 - 1736 were some of the most important in the history of world volcanism, a third of the island was covered with lava and 12 villages were buried as a result. Now part of the area affected by these eruptions forms the Timanfaya National Park and the Los Volcanes Natural Park. As well as visiting Timanfaya, there are a multitude of volcanoes spread throughout the island, some of them can be accessed on foot, through trails, which run through enigmatic and impressive landscapes, almost all of them have been very little traveled.",
      lanzarote4: "The best way to discover the island is by renting a car, getting carried away by some of its roads is a real pleasure for the senses. Discover small charming towns, taste some of their wines in the wineries of La Geria, one of the oldest in Spain, or enjoy the tasty, local food based around fish and meat dishes, cheeses and endless high quality and delicious products.",
      lanzarote5: "In Lanzarote there are a wide variety of beaches, from the most popular, like those in the tourist centers of Puerto del Carmen or Costa Teguise, to untouched, isolated beaches, such as Bajo el Risco or those on the Timanfaya coast.",
      lanzarote6: "There are beaches boasting golden sand, and also those with volcanic black sand, beaches with white sand made of millions of shells and beaches with thousands of olive-green pebbles that shine in the sun. A trip to the small island of La Graciosa can also reveal some of the most beautiful sea shores.",
      lanzarote7: "For sports lovers, the island offers a multitude of activities. Throughout the year a wide variety of sporting events are held, many welcoming international competitors. We are one of the best destinations in Europe for sports such as cycling, trekking, trail running, triathlon ... and of course water sports like surfing, windsurfing, kitesurfing, diving or sailing.",
      lanzarote8: "In short, Lanzarote is an incredible and unique environment for a memorable vacation. Venture into another dimension.",
      lanzarotecaption: "Famara beach",
      centros1: "Art, culture and tourism Centers",
      centros2: "The origin of the art, culture and tourism Centers dates back to the year 1966. They are promoted and managed by the Cabildo de Lanzarote and were created by César Manrique, with the exception of Cueva de Los Verdes, a natural formation which was restored and promoted by Jesús Soto, one of Manrique\"s closest collaborators.",
      centros3: "There are seven Centers; Mirador del Río, Jameos del Agua, Jardin de Cactus, Cueva de los Verdes, Casa Museo del Campesino, Museo Internacional de Arte Contemporáneo MIAC and Montañas del Fuego. All of them are iconic places and together they make up an amazing array of sites full of Lanzarote’s unique identity and beauty.",
      centros4: "Visiting the Centers is a good way to get to know the island. They showcase much of Manrique\"s most significant work, as well as providing good examples of the style, culture and spirit of Lanzarote.",
      centros5: "The headquarters of the César Manrique Foundation should be added to these seven Centers, even though it does not belong to them it is equally representative. It is a private, non-profit cultural institution. The building where it is located was Manrique´s first home in Lanzarote after his return from New York. The house, built on five volcanic bubbles, is one of the most notable examples of “Manrique style”, based on a constant dialogue between the building and nature.",
      manrique1: "Manrique, the artist who shaped the island",
      manrique2: "“When I returned from New York, I came with the intention of turning my home island into one of the most beautiful places on the planet.”.",
      manrique3: "It is impossible to imagine Lanzarote as it is today without César Manrique. He was a painter, sculptor, ecologist, conservator of natural and cultural heritage, designer of buildings and public spaces, and designer of landscapes and gardens ... and above all, a lover of his island.",
      manrique4: "After a two-year stay in New York, César Manrique came back to Lanzarote in 1966. Aware of the uniqueness of the island landscape, he embarked upon an ambitious creative project of intervention in the territory, based around the preservation of the natural and cultural heritage of the island. This idea underpinned the sustainable development model that is now used in Lanzarote. Thanks to the work of Manrique, in 1993 the island was declared a Biosphere Reserve by UNESCO. He developed a new aesthetic ideology, which he called “art-nature / nature-art”, which he applied in his design work. The basis of this is a respectful dialogue between nature and the traditional architectural styles of local tradition, intertwining with modern design concepts. In short, César sought an effort to harmonize, which not only refers to his passion for beauty, but also for life.",
      manrique5: "César Manrique died at the age of 73 in a tragic traffic accident on September 25, 1992. But his valuable legacy will remain forever."
    },
    links: {
      meta: {
        title: 'Links of interest',
        description: 'Interesting links to plan your visit, and our hotel in media'
      },
      title: "Links",
      linksInt: "Links of interest",
      creditos: "Credits",
      saramago: "Casa Museo de José Saramago",
      ayto: "Tías Town Hall",
      arquitectura: "Architecture: ",
      fotografia: "Photography: ",
      desarrollo: "Web development: "
    },
    sustainability: {
      meta: {
        title: 'Sustainability',
        description: 'We understand life always in harmony with nature, and we cannot forget that we are also part of it.'
      },
      title: "Sustainability",
      heroSubtitle: "Let us make your stay respectful of the natural environment.",
      credit: "Ilustration by ",
      parrafo1: "At Los Lirios we want you to enjoy your stay, avoiding this affects the environment. To achieve this, we are adopting a series of measures that respect the natural environment:",
      lista1: "We have our own photovoltaic plant for self-consumption, which provides us with most of the electricity we consume. The remaining energy support is supplied to us by a company that only works with 100% renewable energy.",
      lista1b: "We have installed water and energy saving systems.",
      lista2: "Single-use plastic containers have been removed. We manage waste responsibly.",
      lista3: "In our gardens we apply natural fertilizers from the organic waste we generate.",
      lista4: "Our products and suppliers are from the area, kilometre zero.",
      lista5: "We have minimized food waste with a system of à la carte and on-demand breakfasts. Thus, we only serve what the guest knows they are going to consume and practically nothing is wasted.",
      lista6: "Our old gasoline vehicle has been replaced with a 100% electric one that we charge with the energy produced by our panels.",
      lista7: "We have a hot water system equipped with 3 thermal solar collectors and a 500 liter accumulator. With this equipment we generate all the hot water consumed in the hotel.",
      lista8: "Most of our furniture is made up of recycled and/or restored furniture and decorative objects.",
      lista9: "The building itself provides thermal insulation that significantly reduces the use of electric air conditioners. Very thick walls of volcanic stone and lime mortar that provide a stable and pleasant temperature throughout the year.",
      parrafo2: "We know that there are still many things to do, but we feel that the change has already begun and we think this change begins in each one of us, in another way of understanding life and business. From small to big. As great César Manrique already said, we must always understand life in harmony with nature, and we cannot forget that we are also part of it.",
      saludo: "Cheers!",
      firma: "Maricruz and Antonio."
    },
    legal: {
      meta: {
        title: 'Política de privacidad Hotel Rural Los Lirios',
        description: 'Te informamos sobre nuestra política de privacidad'
      }
    }
  },
  es: {
    general: {
      logotext: "HOTEL RURAL",
      reserva: "¡Reserva a través de nuestra web y consigue un 10% de descuento!",
      pagos: "No efectuamos cargos por adelantado, pagarás en el hotel al final de tu estancia.",
      avisos: "El lugar no es adecuado para niños menores de 13 años. No tenemos camas supletorias. Debe consultarse previamente la admisión de mascotas.",
      verHabitaciones: "Ver las habitaciones",
      prensa: "Nuestro hotel en los medios",
    },
    menu: {
      home: "Inicio",
      hotel: "Hotel",
      rooms: "Habitaciones",
      lanzarote: "Lanzarote",
      links: "Enlaces",
      sustainability: "Sostenibilidad",
      legal: "Aviso legal",
    },
    home: {
      meta: {
        title: 'Hotel Rural Los Lirios en Lanzarote',
        description: 'Nuevo hotel rural en Lanzarote, empezamos con ganas e ilusión esta nueva aventura, ¡compártela con nosotros!'
      },
      title: "Bienvenidos a Los Lirios",
      welcome: "Descubre nuestro encantador hotel en el corazón de Lanzarote",
      heroTitle: "La intimidad, ese lujo...",
      heroSubtitle: "Os esperamos en nuestro pequeño hotel, sin aglomeraciones y en un entorno único.",
      verCasa: "Ver el hotel",
      lanzaroteHighlighted: "Lanzarote, un entorno único por muchos motivos. Declarada Reserva de la Biosfera por la Unesco desde 1993.",
      lanzaroteTxt1: "Su enorme riqueza medioambiental, sus cálidas temperaturas todo el año, junto con una planificación urbanística controlada, hacen de esta isla uno de los destinos turísticos mejor valorados de Europa.",
      lanzaroteTxt2: "Lanzarote es mucho más que sol y playas, esta isla ofrece un amplio abanico de experiencias que el visitante puede disfrutar. Infinidad de actividades deportivas, visitas culturales y etnográficas, centros de arte, yacimientos arqueológicos, impresionantes paisajes volcánicos, rutas por bodegas, gastronomía... y mucho más.",
      compartido: "Un sueño compartido",
      casaHighlighted: "Allá por los años 80, dos amigos alemanes, cargados de ilusión y de ganas de romper con la rutina, se embarcaron en una aventura.",
      casaTxt1: "Compraron esta finca en estado ruinoso y realizaron en ella una labor de reforma intensiva durante varios años, la cual le devolvió la vida. Crearon los jardines y transformaron viejas estancias de la casa en los patios que ahora podemos disfrutar.",
      casaTxt2: "Ellos imaginaron por primera vez la idea de establecer aquí un pequeño hotel, pero por circunstancias de la vida esta idea no pudo materializarse hasta ahora. En el año 2014 llegamos nosotros a la casa y continuamos dando forma a lo que es hoy este lugar. El señor Adam y su amigo Karl han sido piezas fundamentales para que este sueño compartido haya podido hacerse realidad, siempre les estaremos agradecidos.",
      isla: "Descubre nuestra isla",
      seemorelink: "Ver más"
    },
    hotel: {
      meta: {
        title: 'Nuestro hotel en Lanzarote',
        description: 'Estamos muy orgullosos de nuestra casa, que nos conecta con la forma tradicional de vida en Lanzarote.'
      },
      title: "Nuestro hotel",
      heroSubtitle: "Siéntete como en casa",
      lacasaAntonio: "Siempre me han fascinado los lugares especiales, lugares que se diferencian de los demás por transmitir algo que los hace únicos. Cuando llegué por primera vez a Lanzarote sentí que esta isla era especial y cuando entré por primera vez por la puerta de esta casa, supe que éste era mi sitio. Es para mí una enorme satisfacción poder compartir con ustedes, estimados huéspedes, este lugar, espero lo disfruten.",
      lacasaFirma: "Antonio Castillo, Gerente Los Lirios Hotel Rural.",
      lacasaServ0: "Incluimos:",
      lacasaServ1: "Copa y aperitivo de bienvenida",
      lacasaServ2: "Fantástico desayuno",
      lacasaServ3: "Parking privado",
      lacasaServ4: "Conexión wifi",
      lacasaServ5: "Zonas ajardinadas",
      lacasaServ6: "Piscina exterior",
      lacasaServ7: "Terraza solarium",
      lacasaServ8: "servicio de lavandería",
      lacasaServ10: "Limpieza de habitación diaria",
      lacasaServ11: "Información turística",
      lacasaOcio0: "Ocio y actividades:",
      lacasaOcio1: "Excursiones turísticas",
      lacasaOcio2: "Rutas por bodegas",
      lacasaOcio3: "Pesca",
      lacasaOcio4: "Senderismo",
      lacasaOcio5: "Ciclismo",
      lacasaOcio6: "Windsurf",
      lacasaOcio7: "Surf",
      lacasaOcio8: "Kitesurf",
      lacasaOcio9: "Submarinismo",
      ofrecemos: "También ofrecemos ",
      lacasaHighlighted: "La casa, un bien patrimonial protegido por el Gobierno de Canarias, data aproximadamente de la primera mitad del siglo XVIII.",
      lacasa1: "Es una “casa terrera”, edificación popular de tipo agrícola anteriormente vinculada a campos de cultivo y actividad ganadera. Los materiales empleados son muy básicos, piedra del lugar, madera y cal, pero muy efectivos para aislar de la humedad y del calor, además de ser totalmente biodegradables. Este tipo de edificios están perfectamente integrados en el paisaje y el entorno de Lanzarote, es más, en conjunto con el paisaje natural conforman la propia identidad visual de la isla.",
      lacasa2: "De la construcción original se conserva intacta la estructura principal, muros, patio central, forjados de madera, algunos pavimentos de piedra, la era y muchos elementos singulares tales como la destiladera en el patio principal, la pila de piedra volcánica, hornacinas, alacenas y  huecos de ventanas. Todo esto realza el valor histórico del conjunto y nos conecta con la forma tradicional de vida en Lanzarote.",
      lacasa3: "Las distintas intervenciones efectuadas a lo largo de los años a efectos de su restauración, conservación y finalmente adaptación a hotel, han sido totalmente respetuosas con la integridad del edificio original.",
      lacasacaption: "Estado de la construcción en los años 70.",
      tias1: "Tías, nuestro pueblo",
      tias2: "La ubicación de la casa es muy ventajosa, ya que podemos disfrutar de un entorno rural y a la vez tener un acceso fácil y rápido a todos los servicios que podamos necesitar, supermercados, farmacias, tiendas y restaurantes de todo tipo, policía o centro de salud.",
      tias3: "El conjunto del municipio de Tías es muy variado, desde multitud de playas a entornos rurales, desde bulliciosos núcleos turísticos a pequeños pueblos agrícolas. Cabe destacar como lugares de interés la zona de la Geria, de singular belleza y las playas de Puerto del Carmen, que están entre las mejores de la isla. Dentro del núcleo urbano de Tías, además de un par de iglesias típicas, merece la pena visitar la casa museo del escritor y premio Nobel de literatura José Saramago, que vivió en Tías sus últimos 17 años y escribió aquí algunas de sus mejores obras.",
    },
    rooms: {
      meta: {
        title: 'Elige tu habitación',
        description: 'Habitaciones confortables con encanto en un entorno natural'
      },
      title: "Habitaciones",
      heroSubtitle: "Todas nuestras habitaciones son confortables y acogedoras.",
      habitaciones1: "Habitaciones climatizadas, con suelos de madera maciza. Todas tienen baño privado y están equipadas con wi-fi, smart tv con conexión por satélite, secador de pelo, nevera minibar y hervidor de agua con infusiones. Se incluye servicio de limpieza diario y gratuito. Fantástico desayuno incluido. Copa y aperitivo de bienvenida (hasta las 15h).",
      doble: "Doble",
      dobleTxt: "Cama queen size.",
      dobleSuperf: "Superficie habitación: 17 m2.",
      dobleTerraza: "Doble con terraza privada",
      dobleTerrazaTxt: "Cama queen size y terraza.",
      dobleTerrazaSuperf: "Superficie habitación: 20 m2 + terraza 24 m2.",
      dobleSup: "Doble superior",
      dobleSupTxt: "Con cama king size o con 2 camas.",
      dobleSupSuperf: "Superficie habitación: 20 m2.",
      suite: "Suite",
      suiteTxt: "Cama king size.",
      suiteSuperf: "Superficie habitación: 37 m2. Sala de estar y dormitorio independientes."
    },
    lanzarote: {
      meta: {
        title: 'Lanzarote, una isla en otra dimensión',
        description: 'Actividades deportivas, visitas culturales y etnográficas, centros de arte, yacimientos arqueológicos, paisajes volcánicos, rutas por bodegas, gastronomía,...'
      },
      title: "Descubre Lanzarote",
      heroSubtitle: "Una isla en otra dimensión",
      lanzarote1: "Llegar a Lanzarote es como entrar en otra dimensión. Su entorno natural es fascinante, la violenta naturaleza volcánica junto a unas condiciones bioclimáticas semidesérticas han configurado un paisaje natural único y de extraordinaria originalidad.",
      lanzarote2: "Las instituciones conscientes de ello han conseguido que este entorno tan especial se conserve, en su mayor parte, a salvo del crecimiento turístico y del auge de la construcción. Aproximadamente el 40% de la superficie de la isla se encuentra protegida por alguna disposición legal.",
      lanzarote3: "La actividad volcánica de Lanzarote comenzó hace once millones de años, en la actualidad el relieve de la isla está formado por más de 300 conos volcánicos. Las erupciones de 1730 - 1736 fueron unas de las más importantes de la historia del vulcanismo mundial, un tercio de la isla se cubrió de lava y 12 pueblos fueron sepultados. Ahora parte de la zona afectada por dichas erupciones conforma el Parque Nacional de Timanfaya y el Parque Natural de Los Volcanes. Además de la visita a Timanfaya, hay multitud de volcanes repartidos por toda la isla, a algunos de ellos se puede acceder a pie, a través de senderos, los cuales discurren por enigmáticos e impresionantes paisajes, casi todos muy poco transitados.",
      lanzarote4: "La mejor forma de descubrir la isla es alquilando un coche, dejarse llevar por algunas de sus carreteras es un auténtico placer para los sentidos. Descubrir pequeños pueblos con encanto, degustar algunos de sus vinos en las bodegas de La Geria, de las más antiguas de España, o deleitarse con la sabrosa comida local a base de pescados, carnes , quesos, e infinidad de productos de calidad.",
      lanzarote5: "En Lanzarote podemos encontrar una gran variedad de playas, desde las más concurridas, como las de los núcleos turísticos de Puerto del Carmen o Costa Teguise, a playas virgenes y solitarias, como las de Bajo el Risco o las del litoral de Timanfaya.",
      lanzarote6: "Las hay de arena dorada y también de arena negra volcánica, de arena blanca formada por millones de conchas y también las hay con miles de piedrecitas verdes de olivina que brillan bajo el sol. Una excursión a la pequeña isla de La Graciosa nos puede revelar también algunos de los arenales más bellos.",
      lanzarote7: "Para los amantes del deporte la isla ofrece multitud de actividades,  a lo largo del año se celebran muy variados eventos deportivos, algunos de proyección internacional.  Nos encontramos en uno de los mejores destinos de Europa para deportes como el ciclismo, trekking,  trail, triatlón… y por supuesto deportes acuáticos como el surf, windsurf, kitesurf, buceo, vela…",
      lanzarote8: "En resumen, un entorno único para unas vacaciones diferentes. Atrévete a entrar en otra dimensión.",
      lanzarotecaption: "Playa de Famara",
      centros1: "Centros de arte, cultura y turismo",
      centros2: "El origen de los Centros de arte, cultura y turismo se remonta al año 1.966. Son promovidos y gestionados por el Cabildo de Lanzarote y creados por César Manrique, exceptuando la Cueva de Los Verdes, una formación natural que fue acondicionada e iluminada por Jesús Soto, uno de los más estrechos colaboradores de Manrique.",
      centros3: "Los Centros son siete, Mirador del río, Jameos del agua, Jardín de cactus, Cueva de los verdes, Casa museo del campesino, Museo internacional de arte contemporáneo MIAC y Montañas del fuego. Todos ellos son lugares emblemáticos y componen un valioso conjunto cargado de identidad y de belleza.",
      centros4: "Visitar los Centros es una buena manera de conocer la isla en líneas generales. En ellos se resume gran parte de la obra más significativa de Manrique, además de sintetizar el estilo, la cultura y el espíritu de Lanzarote.",
      centros5: "A estos siete Centros habría que sumarle la sede de la Fundación César Manrique, la cual no pertenece a los Centros, pero es igualmente representativa. Se trata de una institución cultural privada sin ánimo de lucro. El edificio donde se encuentra fue la primera vivienda que tuvo Manrique en Lanzarote tras su vuelta de Nueva York. La casa, construida sobre cinco burbujas volcánicas, constituye en sí misma uno de los más notables ejemplos del “estilo Manrique”, el cual se fundamenta en un diálogo constante entre el edificio y la naturaleza.",
      manrique1: "Manrique, el artista que modeló la isla",
      manrique2: "“Cuando regresé de Nueva York, vine con la intención de convertir mi isla natal en uno de los lugares más hermosos del planeta”.",
      manrique3: "Es imposible imaginarse Lanzarote tal y como es hoy sin César Manrique. Era pintor, escultor, ecologista, conservador del patrimonio natural y cultural, diseñador de construcciones y espacios públicos, configurador de paisajes y jardines... y sobre todo, un enamorado de su isla.",
      manrique4: "Tras una estancia de dos años en Nueva York, César Manrique vuelve a Lanzarote en 1966. Consciente de la especial originalidad del paisaje insular, promueve un ambicioso proyecto creativo de intervención en el territorio, cuyo eje central es la preservación del patrimonio natural y cultural de la isla. Esta idea fue determinante para finalmente poder implantar con éxito el modelo de desarrollo sostenible que funciona en Lanzarote, esto sirvió para que en 1.993 la isla fuera declarada Reserva de la Biosfera por la UNESCO. César elaboró un nuevo ideario estético, al que denominó “arte-naturaleza/naturaleza-arte”, el cual aplicó en sus intervenciones espaciales. En su trabajo se mantiene un diálogo respetuoso con el medio natural y se ponen en relación valores arquitectónicos de la tradición local con concepciones modernas. En definitiva, un esfuerzo de armonización, que no solo hace referencia a su pasión por la belleza, sino también por la vida.",
      manrique5: "César Manrique falleció a los 73 años en un trágico accidente de tráfico, el 25 de Septiembre de 1992. Pero su valioso legado permanecerá por siempre.",
    },
    links: {
      meta: {
        title: 'Enlaces de interés',
        description: 'Enlaces interesantes para planificar tu visita, y nuestro hotel en los medios'
      },
      title: "Enlaces",
      linksInt: "Enlaces de interés",
      creditos: "Créditos",
      saramago: "José Saramago museum house",
      ayto: "Ayuntamiento de Tías",
      arquitectura: "Arquitectura: ",
      fotografia: "Fotografía: ",
      desarrollo: "Desarrollo web: "
    },
    sustainability: {
      meta: {
        title: 'Sostenibilidad',
        description: 'Entendemos la vida siempre en armonía con la naturaleza, y no podemos olvidar que nosotros también formamos parte de ella.'
      },
      title: "Sostenibilidad",
      heroSubtitle: "Hagamos que tu estancia sea respetuosa con el entorno natural.",
      credit: "Ilustración de ",
      parrafo1: "En Los Lirios Hotel Rural queremos que disfrutes de tu estancia sin que por ello el medio ambiente se vea afectado. Para conseguirlo estamos adoptando una serie de medidas respetuosas con el entorno natural. Aquí te las contamos:",
      lista1: "Disponemos de una planta fotovoltaica propia para autoconsumo, que nos provee de la mayor parte de la electricidad que consumimos. El apoyo energético restante nos lo suministra una empresa que sólo trabaja con energía 100% renovable.",
      lista1b: "Hemos instalado sistemas de ahorro de agua y energía.",
      lista2: "Los envases de plástico de un solo uso han sido eliminados. Hacemos una gestión de residuos de manera responsable.",
      lista3: "En nuestros jardines aplicamos abonos naturales provenientes de los residuos orgánicos que generamos.",
      lista4: "Nuestros productos y proveedores son de la zona, kilómetro 0.",
      lista5: "Hemos minimizado el desperdicio alimentario con un sistema de desayunos a la carta y por encargo. Así sólo servimos lo que el huésped sabe que va a consumir y no se tira prácticamente nada.",
      lista6: "Nuestro viejo vehículo de gasolina ha sido reemplazado por uno 100% eléctrico que cargamos con la energía que producen nuestras placas.",
      lista7: "Disponemos de un sistema de ACS (agua caliente solar) dotado de 3 captadores solares térmicos y un acumulador de 500 litros. Con este equipo generamos la totalidad del agua caliente que se consume en el hotel.",
      lista8: "La mayor parte de nuestro mobiliario está formado por muebles y objetos de decoración reciclados y/o restaurados.",
      lista9: "La propia edificación aporta un aislamiento térmico que reduce significativamente el uso de los climatizadores eléctricos. Muros de gran grosor de piedra volcánica y mortero de cal que proporcionan una temperatura estable y agradable durante todo el año.",
      parrafo2: "Sabemos que aún quedan muchas cosas por hacer, pero sentimos que el cambio ya se ha iniciado y pensamos que ese cambio empieza en cada uno de nosotros, en otra forma de entender la vida y tu negocio. De lo pequeño a lo grande. Como ya dijo el gran César Manrique, tenemos que entender la vida siempre en armonía con la naturaleza, y no podemos olvidar que nosotros también formamos parte de ella.",
      saludo: "Un abrazo",
      firma: "Maricruz y Antonio.",
    },
    legal: {
      meta: {
        title: 'Privacy policy Los Lirios Rural Hotel',
        description: 'We inform you about our Privacy policy'
      }
    }
  }
};

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('es');

  const translate = (key) => {
    const keys = key.split('.');
    return keys.reduce((obj, key) => obj?.[key], translations[language]) || key;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translate }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);