import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLanguage } from '../context/LanguageContext';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import '../styles/Swiper.css';

const Lanzarote = () => {
  const { translate } = useLanguage();
  const [isImageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const swiper = new Swiper('.swiper', {
      modules: [Navigation],
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 1
      }
    });

    return () => {
      if (swiper) swiper.destroy();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{translate('lanzarote.meta.title')}</title>
        <meta name="description" content={translate('lanzarote.meta.description')} />
      </Helmet>
      <div className="lanzarote-container">
      <div className="swiper-container">
          <div className="swiper" aria-label="Imágenes del hotel Los lirios">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <figure>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/lanzarote-hotel-rural-paisaje.webp`}
                    onLoad={() => setImageLoaded(true)}
                    alt="paisaje de lanzarote" />
                </figure>
              </div>
              <div className="swiper-slide">
                <figure>
                  <img
                    className="swiper-lazy"
                    src={`${process.env.PUBLIC_URL}/img/lanzarote-hotel-rural-atardecer.webp`}
                    alt="atardecer en lanzarote" />
                </figure>
              </div>
              <div className="swiper-slide">
                <figure>
                  <img
                    className="swiper-lazy"
                    src={`${process.env.PUBLIC_URL}/img/lanzarote-hotel-rural-volcan.webp`}
                    alt="volcán del cuervo" />
                </figure>
              </div>
            </div>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </div>
          {isImageLoaded &&
            <div className='swiper-container__text'>
              <h1>{translate('lanzarote.title')}</h1>
              <p>{translate('lanzarote.heroSubtitle')}</p>
            </div>
          }
        </div>
        <div className="padded-text">
          <p className='highlighted-text'>{translate('lanzarote.lanzarote1')}</p>
          <p>{translate('lanzarote.lanzarote2')}</p>
          <p>{translate('lanzarote.lanzarote3')}</p>
          <p>{translate('lanzarote.lanzarote4')}</p>
          <figure>
            <img src={`${process.env.PUBLIC_URL}/img/famara.webp`} alt="playa de famara" />
            <figcaption>{translate('lanzarote.lanzarotecaption')}</figcaption>
          </figure>
          <p className='highlighted-text'>{translate('lanzarote.lanzarote5')}</p>
          <p>{translate('lanzarote.lanzarote6')}</p>
          <p>{translate('lanzarote.lanzarote7')}</p>
          <p>{translate('lanzarote.lanzarote8')}</p>
          {/* <figure>
            <img src={`${process.env.PUBLIC_URL}/img/lanzarote-hotel-rural-volcan.webp`} alt="volcán del cuervo" />
          </figure> */}
        </div>

        <div className="padded-text columns-bio">
          <div>
            <h2>{translate('lanzarote.manrique1')}</h2>
            <p className='cita'>{translate('lanzarote.manrique2')}</p>
            <p>{translate('lanzarote.manrique3')}</p>
            <p>{translate('lanzarote.manrique4')}</p>
            <p>{translate('lanzarote.manrique5')}</p>
          </div>
          <img src={`${process.env.PUBLIC_URL}/img/manrique.webp`} alt="servicios en Tías, nuestro pueblo" />
        </div>

        <div className="highlighted-box padded-text">
          <img src={`${process.env.PUBLIC_URL}/img/jameos-del-agua.webp`} alt="servicios en Tías, nuestro pueblo" />
          <div className='highlighted-box__text'>
            <h2>{translate('lanzarote.centros1')}</h2>
            <p>{translate('lanzarote.centros2')}</p>
            <p>{translate('lanzarote.centros3')}</p>
            <p>{translate('lanzarote.centros4')}</p>
            <p>{translate('lanzarote.centros5')}</p>
          </div>
          <img src={`${process.env.PUBLIC_URL}/img/jameos-agua.webp`} alt="servicios en Tías, nuestro pueblo" />
        </div>
      </div>
    </>
  );
};

export default Lanzarote;