import React from 'react';
import { useNavigate } from 'react-router-dom';

const NavigationButton = ({ to, children, className = "button" }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const [, hash] = to.split('#'); // comma to skip the first element in the array
    
    navigate(to);

    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
          element.scrollIntoView({ behavior: isSafari ? 'auto' : 'smooth' });
        }
      }, 200);
    } else {
      window.scrollTo({ top: 0 });
    }
  };

  return (
    <button className={className} onClick={handleClick}>
      {children}
    </button>
  );
};

export default NavigationButton;