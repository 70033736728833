import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLanguage } from '../context/LanguageContext';
import NavigationButton from '../components/NavigationButton';

const Home = () => {
  const { translate } = useLanguage();
  const [isImageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      <Helmet>
        <title>{translate('home.meta.title')}</title>
        <meta name="description" content={translate('home.meta.description')} />
      </Helmet>
      <div className="home-container">

        <div className="hero">
          <figure>
            <img
              src={`${process.env.PUBLIC_URL}/img/piscina-hotel-lirios.webp`}
              onLoad={() => setImageLoaded(true)}
              alt="piscina del hotel" />
          </figure>
          {isImageLoaded &&
            <div className="hero__text">
                <div className="logo">
                  <img src={`${process.env.PUBLIC_URL}/img/los-lirios-logo.svg`} alt="" />
                  <div className="logo__lirios">LOS LIRIOS</div>
                  <div className="logo__hotel">
                    {translate('general.logotext')}
                  </div>
                </div>
                <h1>{translate('home.heroTitle')}</h1>
                <p>{translate('home.heroSubtitle')}</p>
            </div>
          }
        </div>

        <article className="article-home">
          <div className="article-home__image animate-on-scroll">
            <h3 className="article-home__title">
              {translate('home.compartido')}
              <NavigationButton to="/hotel">
                  <img src={`${process.env.PUBLIC_URL}/img/arrow.svg`} alt={translate('home.seemorelink')} />
              </NavigationButton>
            </h3>
            <figure>
              <img src={`${process.env.PUBLIC_URL}/img/hotel-rural-lanzarote-lirios.webp`} loading="lazy" alt="terraza del hotel" />
            </figure>
          </div>

          <p className="padded-text">
            <span className='highlighted-text'>{translate('home.casaHighlighted')}</span>
            <span>{translate('home.casaTxt1')}</span>
            <span>{translate('home.casaTxt2')}</span>
          </p>
        </article>

        <article className="article-home">
          <div className="article-home__image animate-on-scroll">
            <h3 className="article-home__title">
              {translate('home.isla')}
              <NavigationButton to="/lanzarote">
                  <img src={`${process.env.PUBLIC_URL}/img/arrow.svg`} alt={translate('home.seemorelink')} />
              </NavigationButton>
            </h3>
            <figure>
              <img src={`${process.env.PUBLIC_URL}/img/charco-lanzarote.webp`} loading="lazy" alt="charco verde" />
            </figure>
          </div>

          <p className="padded-text">
            <span className='highlighted-text'>{translate('home.lanzaroteHighlighted')}</span>
            <span>{translate('home.lanzaroteTxt1')}</span>
            <span>{translate('home.lanzaroteTxt2')}</span>
          </p>
        </article>

        <div className="awards">
          <a href="https://www.kayak.es/Tias-Hoteles-Los-Lirios-Hotel-Rural.5274018.ksp" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/img/awards/kajak-awards.webp`} alt="Kajak award" />
          </a>
          <a href="/sostenibilidad.php">
            <img src={`${process.env.PUBLIC_URL}/img/awards/GrINN-Awards-2022-Best-Sustainable-Accommodation-Spain.webp`} alt="Mejor hotel rural sostenible de España. Grinn Awards 2022" />
          </a>
          <a href="/sostenibilidad.php">
            <img src={`${process.env.PUBLIC_URL}/img/awards/GrINN-Awards-2022-Best-Sustainable-Hotel-Rural.webp`} alt="Segundo mejor hotel rural sostenible de Europa. Grinn Awards 2022" />
          </a>
          <a href="https://www.booking.com/hotel/es/los-lirios-rural-adults-only.es.html" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/img/awards/digital-award.webp`} alt="Booking award" />
          </a>
          <a href="https://www.travelmyth.co.uk/Tias/Hotels" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/img/awards/top-hotel-in-tias.webp`} alt="TravelMyth award" />
          </a>
          <a href="https://www.tripadvisor.es/Hotel_Review-g954043-d19102189-Reviews-Los_Lirios_Hotel_Rural-Tias_Lanzarote_Canary_Islands.html" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/img/awards/travelers.webp`} alt="TripAdvisor" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Home;