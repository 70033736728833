import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLanguage } from '../context/LanguageContext';
import NavigationButton from '../components/NavigationButton';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import '../styles/Swiper.css';

const Hotel = () => {
  const { translate } = useLanguage();
  const [isImageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const swiper = new Swiper('.swiper', {
      modules: [Navigation],
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 1
      }
    });

    return () => {
      if (swiper) swiper.destroy();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{translate('hotel.meta.title')}</title>
        <meta name="description" content={translate('hotel.meta.description')} />
      </Helmet>
      <div className="hotel-container">
        <div className="swiper-container">
          <div className="swiper" aria-label="Imágenes del hotel Los lirios">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <figure>
                  <img
                    src={`${process.env.PUBLIC_URL}/img/hotel-rural-lanzarote-comedor.webp`}
                    onLoad={() => setImageLoaded(true)}
                    alt="comedor del hotel" />
                </figure>
              </div>
              <div className="swiper-slide">
                <figure>
                  <img
                    className="swiper-lazy"
                    src={`${process.env.PUBLIC_URL}/img/hotel-rural-lanzarote-casa.webp`}
                    alt="casa terrera" />
                </figure>
              </div>
              <div className="swiper-slide">
                <figure>
                  <img
                    className="swiper-lazy"
                    src={`${process.env.PUBLIC_URL}/img/hotel-rural-lanzarote-exterior.webp`}
                    alt="piscina del hotel" />
                </figure>
              </div>
              <div className="swiper-slide">
                <figure>
                  <img
                    className="swiper-lazy"
                    src={`${process.env.PUBLIC_URL}/img/entrada-hotel-lirios.webp`}
                    alt="entrada al hotel" />
                </figure>
              </div>
              <div className="swiper-slide">
                <figure>
                  <img
                    className="swiper-lazy"
                    src={`${process.env.PUBLIC_URL}/img/hotel-rural-lanzarote-relax.webp`}
                    alt="un patio del hotel" />
                </figure>
              </div>
              <div className="swiper-slide">
                <figure>
                  <img
                    className="swiper-lazy"
                    src={`${process.env.PUBLIC_URL}/img/hotel-rural-lanzarote-habitaciones.webp`}
                    alt="acceso a las habitaciones" />
                </figure>
              </div>
            </div>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </div>
          {isImageLoaded &&
            <div className='swiper-container__text'>
              <h1>{translate('hotel.title')}</h1>
              <p>{translate('hotel.heroSubtitle')}</p>
            </div>
          }
        </div>

        <div className="padded-text cita">
          <p>{translate('hotel.lacasaAntonio')}</p>
          <p>{translate('hotel.lacasaFirma')}</p>
        </div>

        <div className='padded-text columns'>
          <div>
            <div>{translate('hotel.lacasaServ0')}</div>
            <div className='servBox'>
              <div>{translate('hotel.lacasaServ1')}</div>
              <div>{translate('hotel.lacasaServ2')}</div>
              <div>{translate('hotel.lacasaServ3')}</div>
              <div>{translate('hotel.lacasaServ4')}</div>
              <div>{translate('hotel.lacasaServ5')}</div>
              <div>{translate('hotel.lacasaServ6')}</div>
              <div>{translate('hotel.lacasaServ7')}</div>
              <div>{translate('hotel.lacasaServ10')}</div>
              <div>{translate('hotel.lacasaServ11')}</div>
            </div>
            <p>{translate('hotel.ofrecemos')}{translate('hotel.lacasaServ8')}</p>
          </div>

          <div>
            <div className="">{translate('hotel.lacasaOcio0')}</div>
            <div className="ocioBox">
              <div>{translate('hotel.lacasaOcio2')}</div>
              <div>{translate('hotel.lacasaOcio1')}</div>
              <div>{translate('hotel.lacasaOcio3')}</div>
              <div>{translate('hotel.lacasaOcio4')}</div>
              <div>{translate('hotel.lacasaOcio5')}</div>
              <div>{translate('hotel.lacasaOcio6')}</div>
              <div>{translate('hotel.lacasaOcio7')}</div>
              <div>{translate('hotel.lacasaOcio8')}</div>
              <div>{translate('hotel.lacasaOcio9')}</div>
            </div>
          </div>
        </div>

        <div className='padded-text'>
          <p className='highlighted-text'>{translate('hotel.lacasaHighlighted')}</p>
          <p>{translate('hotel.lacasa1')}</p>
          <figure>
            <img src={`${process.env.PUBLIC_URL}/img/los-lirios-casa.webp`} alt="atardecer en lanzarote" />
            <figcaption>{translate('hotel.lacasacaption')}</figcaption>
          </figure>
          <p>{translate('hotel.lacasa2')}</p>
          <p>{translate('hotel.lacasa3')}</p>

          <NavigationButton to="/rooms">
            <img src={`${process.env.PUBLIC_URL}/img/arrow.svg`} alt="" />
            <span>{translate('general.verHabitaciones')}</span>
          </NavigationButton>
        </div>

        <div className="highlighted-box">
          {/* <img src={`${process.env.PUBLIC_URL}/img/tias-nuestro-pueblo.webp`} alt="servicios en Tías, nuestro pueblo" /> */}
          <div className='highlighted-box__text'>
            <h2>{translate('hotel.tias1')}</h2>
            <p>{translate('hotel.tias2')}</p>
            <p>{translate('hotel.tias3')}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hotel;