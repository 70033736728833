import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../context/LanguageContext';
import '../styles/Footer.css';

const Footer = () => {
  const { translate } = useLanguage();

  const footerItems = [
    { text: translate('menu.legal'), path: '/legal' },
  ];

  return (
    <footer>
      <a className="footer__logo" href='/'>
        <img src={`${process.env.PUBLIC_URL}/img/los-lirios-logo.svg`} alt="logotipo Los Lirios" />
        <div className="logo__lirios">LOS LIRIOS</div>
        <div className="logo__hotel">{translate('general.logotext')}</div>
      </a>
      <div className="footer__links">
        <a href="mailto:mail@loslirioshotel.es">mail@loslirioshotel.es</a>
        <a href="tel:+34928831736">+34 928 83 17 36</a>
        <a href="https://www.google.es/maps/place/Hotel+Los+Lirios/@28.9584588,-13.6586324,15z/data=!4m5!3m4!1s0x0:0xcbdb9807148643b!8m2!3d28.9584588!4d-13.6586324" target="_blank" rel="noopener noreferrer">Calle Los Lirios, 25, 35572 Tías (Lanzarote)</a>
        <a href="https://facebook.com/loslirioshotellanzarote" id="facebook" target="_blank" rel="noopener noreferrer">Facebook</a>
        <a href="https://www.instagram.com/loslirioshotelrural" id="instagram" target="_blank" rel="noopener noreferrer">Instagram</a>
        {footerItems.map((item) => (
            <div key={item.text} className="footer__legal">
              <Link to={item.path} onClick={() => {
                  window.scrollTo({ top: 0 });
                }}>
                {item.text}
              </Link>
            </div>
          ))}
      </div>
      <div className="footer__avisos">
        <div className="footer__warning">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#00B189"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16 10C17.1046 10 18 9.10457 18 8C18 6.89543 17.1046 6 16 6C14.8954 6 14 6.89543 14 8C14 9.10457 14.8954 10 16 10ZM16 12.4C15.3262 12.4 14.7691 12.9252 14.7295 13.5979L14.1174 24.0035C14.0537 25.0866 14.915 26 16 26C17.085 26 17.9463 25.0866 17.8826 24.0035L17.2705 13.5979C17.2309 12.9252 16.6738 12.4 16 12.4Z" fill="white"/>
          </svg>
          <p>{translate('general.pagos')}</p>
        </div>
        <div className="footer__warning">
          <svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.1865 3C18.9563 1.66667 20.8808 1.66667 21.6506 3L38.1051 31.5C38.8749 32.8333 37.9127 34.5 36.3731 34.5H3.4641C1.9245 34.5 0.96225 32.8333 1.73205 31.5L18.1865 3Z" fill="#FFC800"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M20 26C18.8954 26 18 26.8954 18 28C18 29.1046 18.8954 30 20 30C21.1046 30 22 29.1046 22 28C22 26.8954 21.1046 26 20 26ZM20 23.6C20.6738 23.6 21.2309 23.0748 21.2705 22.4021L21.8826 11.9965C21.9463 10.9134 21.085 10 20 10C18.915 10 18.0537 10.9134 18.1174 11.9965L18.7295 22.4021C18.7691 23.0748 19.3262 23.6 20 23.6Z" fill="white"/>
          </svg>
          <p>{translate('general.avisos')}</p>
        </div>
      </div>
      <div className="footer__banner">
        <p>{translate('general.reserva')}</p>
      </div>
    </footer>
  );
};

export default Footer;