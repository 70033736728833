import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLanguage } from '../context/LanguageContext';
import NavigationButton from '../components/NavigationButton';

const Sustainability = () => {
  const { translate } = useLanguage();
  const [isImageLoaded, setImageLoaded] = useState(false);

  return (
    <>
      <Helmet>
        <title>{translate('sustainability.meta.title')}</title>
        <meta name="description" content={translate('sustainability.meta.description')} />
      </Helmet>        
      <div className="sustainability-container">
        <div className="hero">
          <figure>
            <img
              src={`${process.env.PUBLIC_URL}/img/selva.webp`}
              onLoad={() => setImageLoaded(true)}
              alt="" />
            <div className="caption">{translate('sustainability.credit')}<a href="https://www.instagram.com/fatimorri/" target="_blank" rel="noreferrer noopener">Fátima Moreno</a></div>
          </figure>
          {isImageLoaded &&
            <div className="hero__text hero__text--sustainability">
              <h1>{translate('sustainability.title')}</h1>
              <p>{translate('sustainability.heroSubtitle')}</p>
            </div>
          }
        </div>
        <div className="padded-text">
          <p>{translate('sustainability.parrafo1')}</p>
        </div>
        <div className='highlighted-box highlighted-box--reverse'>
          {/* <img src={`${process.env.PUBLIC_URL}/img/sustainability-1.webp`} alt="" /> */}
          <div className='highlighted-box__text'>
            <ul>
              <li>{translate('sustainability.lista1')}</li>
              <li>{translate('sustainability.lista1b')}</li>
              <li>{translate('sustainability.lista2')}</li>
              <li>{translate('sustainability.lista3')}</li>
              <li>{translate('sustainability.lista4')}</li>
              <li>{translate('sustainability.lista5')}</li>
              <li>{translate('sustainability.lista6')}</li>
              <li>{translate('sustainability.lista7')}</li>
              <li>{translate('sustainability.lista8')}</li>
              <li>{translate('sustainability.lista9')}</li>
            </ul>
          </div>
          {/* <img src={`${process.env.PUBLIC_URL}/img/sustainability-2.webp`} alt="" /> */}
        </div>
        <div className='padded-text'>
          <p>{translate('sustainability.parrafo2')}</p>
          <p>{translate('sustainability.saludo')}<br/>{translate('sustainability.firma')}</p>
          <NavigationButton to="/links#media-container">
            <img src={`${process.env.PUBLIC_URL}/img/arrow.svg`} alt="" />
            <span>{translate('general.prensa')}</span>
          </NavigationButton>
        </div>
      </div>
    </>
  );
};

export default Sustainability;